export default [
  {
    key: 'Teléfono',
    label: 'Telefone'
  },
  {
    key: 'App Clientes',
    label: 'Aplicação do cliente'
  },
  {
    key: 'Web',
    label: 'Web'
  },
  {
    key: 'Redes sociales',
    label: 'Redes sociais'
  },
  {
    key: 'Correo',
    label: 'e-mail'
  },
  {
    key: 'Partners B2B',
    label: 'Parceiros B2B'
  },
  {
    key: 'eCommerce',
    label: 'loja online'
  }
]
