export default [
  {
    key: 'Cliente directo',
    label: 'Cliente directo'
  },
  {
    key: 'Distribuidor autorizado',
    label: 'Distribuidor autorizado'
  },
  {
    key: 'Marketing',
    label: 'Marketing'
  },
  {
    key: 'Ventas',
    label: 'Ventas'
  },
  {
    key: 'Proyectos',
    label: 'Proyectos'
  }
]
