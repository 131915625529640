export default [
  {
    key: 'Teléfono',
    label: 'Teléfono'
  },
  {
    key: 'App Clientes',
    label: 'App Clientes'
  },
  {
    key: 'Web',
    label: 'Web'
  },
  {
    key: 'Redes sociales',
    label: 'Redes sociales'
  },
  {
    key: 'Correo',
    label: 'Correo'
  },
  {
    key: 'Partners B2B',
    label: 'Partners B2B'
  },
  {
    key: 'eCommerce',
    label: 'eCommerce'
  }
]
